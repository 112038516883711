// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/cardSwipe.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/playButton.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".launch-view{text-align:center;background-image:-webkit-gradient(linear,left top,left bottom,from(#1cd4e3),color-stop(#3770fe),to(#3770fe));background-image:linear-gradient(#1cd4e3,#3770fe,#3770fe);color:#fff!important;max-width:600px!important}.launch-view .welcome-actions{width:100%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:space-evenly;-ms-flex-pack:space-evenly;justify-content:space-evenly;margin-top:25px;margin-bottom:15px!important;padding-bottom:25px}.launch-view .congrats-icon{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:100% 100%;width:300px;height:192.86px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin:auto}.launch-view .play{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");color:#000;background-size:100% 100%;width:75px;height:75px;border-radius:36.5px;display:inline-block;cursor:pointer}.launch-view .play:hover{-webkit-box-shadow:0 5px 20px rgba(0,0,0,.3);box-shadow:0 5px 20px rgba(0,0,0,.3);-webkit-transition:opacity .3s ease-in-out;transition:opacity .3s ease-in-out}", ""]);
// Exports
module.exports = exports;
