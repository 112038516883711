<template>
	<v-card flat class="launch-view" style="border-radius: 8px; margin: auto">
		<v-card-title justify="center" align="center"
			><h1
				style="color: white; font-size: 1.75rem; line-height: 1.2em; marign-bottom: 50px; text-align: center; margin: auto"
			>
				Engage with donors, <br />
				every time they swipe a card.
			</h1></v-card-title
		>
		<div class="congrats-icon">
			<div class="play" @click="playVideo" />
			<p
				v-bind:style="{
					marginTop: '-5px',
					fontWeight: 600,
					color: 'black'
				}"
			>
				Click to play
			</p>
		</div>
		<p style="color: white; line-height: 1.2em">
			The RoundUp App is the #1 fundraising tool that automatically rounds
			up users’ credit or debit card transactions and donates the "change"
			to your organization, giving you a new stream of recurring
			donations.
		</p>
		<v-card-actions class="welcome-actions">
			<RoundupButton
				color="white"
				@click="navigate({ name: 'register' }, 'up')"
			>
				Register Your Organization</RoundupButton
			>
			<RoundupButton
				color="white"
				@click="navigate({ name: 'login' }, 'up')"
				>Login</RoundupButton
			>
		</v-card-actions>

		<modal v-model="showVideo" size="large">
			<iframe
				width="750"
				height="421.875"
				src="https://www.youtube.com/embed/p2l7z1Vs3jE?autoplay=1"
				frameborder="0"
				allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
				allowfullscreen
			></iframe>
		</modal>
	</v-card>
</template>

<script>
import UiButton from '../../ui/Button'
import Screen from '../../ui/Screen'
import Tutorial from '../../ui/Tutorial'
import Modal from '../../ui/Modal'

export default {
	name: 'launch-view',
	components: { UiButton, Screen, Tutorial, Modal },
	data() {
		return {
			showVideo: false
		}
	},
	methods: {
		playVideo() {
			this.showVideo = true
		}
	},
	mounted() {}
}
</script>

<style lang="scss">
@import '../../../styles/variables';

.launch-view {
	text-align: center;
	background-image: linear-gradient(#1cd4e3, #3770fe, #3770fe);
	color: #fff !important;
	max-width: 600px !important;
	.welcome-actions {
		width: 100%;
		display: flex;
		justify-content: space-evenly;
		margin-top: 25px;
		margin-bottom: 15px !important;
		padding-bottom: 25px;
	}
	.congrats-icon {
		background: url('../../../assets/cardSwipe.png');
		background-size: 100% 100%;
		width: 300px;
		height: 192.86px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: auto;
	}
	.play {
		background: url('../../../assets/playButton.svg');
		color: #000;
		background-size: 100% 100%;
		width: 75px;
		height: 75px;
		border-radius: 36.5px;
		display: inline-block;
		cursor: pointer;
		&:hover {
			box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
			transition: opacity 0.3s ease-in-out;
		}
	}
}
</style>
